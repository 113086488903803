import { Logo } from '@src/modules/shared/components/logo';
import { IconChevronUp } from '@tabler/icons-react';
import type { ReactElement } from 'react';
import { Fragment, useLayoutEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import styles from './index.module.scss';

export function PageFooter(): ReactElement {
	const [expandDetails, setExpandDetails] = useState(true);
	const expandDetailsDebounced = useDebounce(expandDetails, 500);

	useLayoutEffect(() => {
		function handleResize() {
			const sw = document.body.clientWidth;
			setExpandDetails(sw >= 1024);
		}

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<Fragment>
			<div className={styles.footer}>
				<div className="container">
					<p className="mb-8">
						As a family-owned company with over eight decades of experience in filtration, combined with the experience
						of our employees at over 80 sites worldwide and our innovative solutions, we can proudly say we are a global
						technology leader in filtration. Convinced by our vision of "Leadership in Filtration", we assume
						responsibility towards current and future generations and have successfully relied on continuity and
						reliability since our foundation in 1941.
					</p>
					<div className={styles.wrapper}>
						<div className={styles.left}>
							<details open={expandDetailsDebounced} className="hidden">
								<summary>
									<h2>Company</h2>
									<IconChevronUp className={styles.arrow} />
								</summary>
								<ul>
									<li>
										<a href="https://www.mann-hummel.com/en/career.html" target="_blank">
											Career
										</a>
									</li>
									<li>
										<a href="https://www.mann-hummel.com/en/company/responsibility/sustainability.html" target="_blank">
											Sustainability
										</a>
									</li>
									<li>
										<a href="https://www.mann-hummel.com/en/company/credentials.html" target="_blank">
											Credentials
										</a>
									</li>
									<li>
										<a href="https://www.mann-hummel.com/en/company/news-press.html" target="_blank">
											News & Press
										</a>
									</li>
									<li>
										<a href="https://www.mann-hummel.com/en/company/about-us/locations.html" target="_blank">
											Locations
										</a>
									</li>
								</ul>
							</details>
							<details open={expandDetailsDebounced}>
								<summary>
									<h2>Service</h2>
									<IconChevronUp className={styles.arrow} />
								</summary>
								<ul>
									<li>
										<a href="https://www.mann-hummel.com/en/contact.html" target="_blank">
											Contact
										</a>
									</li>
									<li>
										<a href="https://www.mann-hummel.com/en/company/downloads.html" target="_blank">
											Downloads
										</a>
									</li>
									<li>
										<a href="https://www.mann-hummel.com/en/data-privacy.html" target="_blank">
											Privacy statement
										</a>
									</li>
									<li>
										<a href="https://www.mann-hummel.com/en/imprint.html" target="_blank">
											Imprint
										</a>
									</li>
									<li>
										<a href="https://www.mann-hummel.com/en/legal-notice.html" target="_blank">
											Legal notice
										</a>
									</li>
								</ul>
							</details>
							<details open={expandDetailsDebounced}>
								<summary>
									<h2>Social Media</h2>
									<IconChevronUp className={styles.arrow} />
								</summary>
								<ul>
									<li>
										<a href="https://www.facebook.com/mannhummelofficial" target="_blank">
											<span className={styles.facebook}>Facebook</span>
										</a>
									</li>
									<li>
										<a href="https://www.instagram.com/mannhummelofficial/" target="_blank">
											<span className={styles.instagram}>Instagram</span>
										</a>
									</li>
									<li>
										<a href="https://www.linkedin.com/company/mannhummel/" target="_blank">
											<span className={styles.linkedin}>LinkedIn</span>
										</a>
									</li>
									<li>
										<a href="https://twitter.com/mannhummelgroup" target="_blank">
											<span className={styles.twitter}>Twitter</span>
										</a>
									</li>
									<li>
										<a href="https://www.youtube.com/user/MANNHUMMELGroup" target="_blank">
											<span className={styles.youtube}>YouTube</span>
										</a>
									</li>
								</ul>
							</details>
						</div>
						<div className={styles.right}>
							<a href="https://www.mann-hummel.com/" target="_blank" className="h-12" title="Mann+Hummel">
								<Logo className="h-full w-full object-contain" aria-hidden />
							</a>
							{/*prettier-ignore*/}
							<div className="pt-6 text-gray-600">
								MANN+HUMMEL Filtration Technology US LLC<br />
								1 Wix Way<br />
								Gastonia, NC 28054<br />
								(704) 869-3300<br />
								<a href="https://www.mann-hummel.com/en/contact.html" target="_blank"
								   className="text-primary-700">Contact
									Us</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.copyright}>
				<div className="container py-4">Copyright by MANN+HUMMEL {new Date().getFullYear()}</div>
			</div>
		</Fragment>
	);
}
